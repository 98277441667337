import * as React from 'react'
import Section from 'src/components/section'
import RichText from 'src/components/rich-text'
import TypingText from 'src/components/typing-text'
import 'src/styles/section-form.sass'
import { win } from 'src/utils'

class SectionForm extends React.Component {
  componentDidMount = () => {
    if (win && win.MktoForms2) {
      MktoForms2.loadForm('//go.impact.com', '280-XQP-994', 2277)
    }
  }

  render() {
    return (
      <Section
        style={this.props.style}
        section={this.props.section}
        focused={this.props.focused}
      >
        <div className="content">
          <div className="copy">
            <div className="logo">
              <img src={this.props.section.data.logo} alt="Form Section Logo" />
            </div>
            <RichText className="title">
              {this.props.section.data.title}
            </RichText>
            <TypingText
              className="subtitle"
              copy={this.props.section.data.subtitle}
              animateIn={this.props.focused}
              startInDelay={350}
              characterDelayIn={25}
            />
          </div>
          <div className="form">
            <form id="mktoForm_2277"></form>
          </div>
        </div>
      </Section>
    )
  }
}

export default SectionForm
