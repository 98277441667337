import * as React from 'react'
import { connect } from 'react-redux'
import Section from 'src/components/section'
import TypingText from 'src/components/typing-text'
import RichText from 'src/components/rich-text'
import { gotoNextSection, selectAnswer } from 'src/state/app'
import 'src/styles/section-guess-who.sass'

class SectionGuessWho extends React.Component {
  render() {
    return (
      <Section
        style={this.props.style}
        section={this.props.section}
        focused={this.props.focused}
      >
        <div className="content">
          <TypingText
            className="section-title"
            copy={this.props.section.data.title}
            animateIn={this.props.focused}
            startInDelay={350}
            characterDelayIn={45}
          />
          <div className="section-subtitle">
            {this.props.section.data.subtitle}
          </div>
          <div className="suspects">
            {this.props.suspects.map((suspect, i) => (
              <div
                className="suspect"
                onClick={() => {
                  this.props.dispatch(selectAnswer(suspect))
                  this.props.dispatch(gotoNextSection())
                }}
                key={suspect.id}
              >
                <div className="thumb">
                  <img src={suspect.hero.url} alt={suspect.hero.alt} />
                </div>
                <div className="index">{i + 1}</div>
                <div className="copy desktop">
                  <RichText>{suspect.title}</RichText>
                </div>
                <div className="copy mobile">
                  <RichText>{suspect.name}</RichText>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Section>
    )
  }
}

export default connect((state) => ({
  suspects: state.app.suspects.map((suspect) => ({
    section: state.app.sections.find((section) => section.id === suspect.id),
    ...suspect,
  })),
}))(SectionGuessWho)
