import * as React from 'react'
import 'src/styles/rich-text.sass'

class RichText extends React.Component {
  static defaultProps = {
    children: '',
    className: '',
  }

  render() {
    return (
      <div
        className={`
          rich-text
          ${this.props.className}
        `}
        dangerouslySetInnerHTML={{ __html: this.props.children }}
      />
    )
  }
}

export default RichText
