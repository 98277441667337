import { detect } from 'detect-browser'

export const browser = detect()

export const win = typeof window === 'undefined' ? null : window

export const randomNumber = (min, max) => {
  return Math.random() * (max - min) + min
}

export const trackSectionView = (section) => {
  if (!win || !win.dataLayer) return
  win.dataLayer.push({
    event: 'sectionView',
    sectionId: section.id,
    navText: section.navText,
  })
}

export const forceLinksToOpenNewTab = () => {
  if (!win) return
  const links = document.querySelectorAll('a')
  links.forEach((link) => {
    link.setAttribute('target', '_blank')
  })
}
