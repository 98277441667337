import SectionLanding from 'src/components/section-landing'

export default {
  id: 'landing',
  navText: 'Suspects',
  Component: SectionLanding,
  showNav: true,
  showChevron: true,
  data: {
    longCopy:
      "Let's take a look at the suspects, weapons of choice, and locations of the crime.",
    shortCopy: '?',
  },
}
