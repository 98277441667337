import * as React from 'react'
import 'src/styles/section.sass'

class Section extends React.Component {
  static defaultProps = {
    className: '',
    focused: false,
  }

  state = {
    canFocus: false,
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({ canFocus: true })
    }, 10)
  }

  render() {
    const className = `
      section
      section-${this.props.section.id}
      ${this.props.focused && this.state.canFocus ? 'focused' : ''}
      ${this.props.className}
    `
    return (
      <section className={className} style={this.props.style}>
        <div className="children">{this.props.children}</div>
      </section>
    )
  }
}

export default Section
