import light1 from 'src/assets/story-1-light.svg'
import dark1 from 'src/assets/story-1-dark.svg'
import light2 from 'src/assets/story-2-light.svg'
import dark2 from 'src/assets/story-2-dark.svg'
import light3 from 'src/assets/story-3-light.svg'
import dark3 from 'src/assets/story-3-dark.svg'
import light4 from 'src/assets/story-4-light.svg'
import dark4 from 'src/assets/story-4-dark.svg'
import light5 from 'src/assets/story-5-light.svg'
import dark5 from 'src/assets/story-5-dark.svg'

export const stories = [
  {
    logoLight: {
      url: light1,
      alt: 'Alt Text',
    },
    logoDark: {
      url: dark1,
      alt: 'Alt Text',
    },
    type: 'B2B Partnership',
    body: `B2B partnerships let brands refer their own customers to other brands with overlapping or complementary audiences. For instance, Ticketmaster partnered with Spotify — as Spotify users search for music, they can now easily see upcoming shows and purchase concert tickets within the Spotify app. This is a convenient experience for customers and a partnership that ultimately benefits both brands. Ticketmaster generates sales and Spotify is rewarded with a payout each time they refer a new customer to Ticketmaster.`,
  },
  {
    logoLight: {
      url: light2,
      alt: 'Alt Text',
    },
    logoDark: {
      url: dark2,
      alt: 'Alt Text',
    },
    type: 'Commerce Content Partnership',
    body: `“Com-tent” partnerships help brands place their products within shoppable, high-intent posts on premium publishers’ properties. BuzzFeed, for example partnered with Walmart for articles like “All The Best Things to Buy at Walmart’s Spring Rollbacks Sale” to drive sales at Walmart, while also handpicking the best products and providing true value for their audience.`,
  },
  {
    logoLight: {
      url: light3,
      alt: 'Alt Text',
    },
    logoDark: {
      url: dark3,
      alt: 'Alt Text',
    },
    type: 'Affiliate Partnership',
    body: `Affiliate partnerships are a great way to increase conversion rates and drive customer loyalty through cashback, deals, and other incentives. Miles is an app that rewards users for sustainable travel, including walking, biking, and public transportation. Users can then redeem their “miles” for exclusive perks with partnering brands, like Postmates. This introduces a new audience to Postmates while also encouraging brand loyalty for existing users.`,
  },
  {
    logoLight: {
      url: light4,
      alt: 'Alt Text',
    },
    logoDark: {
      url: dark4,
      alt: 'Alt Text',
    },
    type: 'Influencer Partnership',
    body: `Influencer partnerships gives brands a way to access influencers’ audiences while honoring the trust that makes those audiences so receptive. Glossier partners with influencers like Chloe Wen to showcase their products. This fits authentically with Chloe’s content, as she features their makeup on her beauty and lifestyle YouTube channel, and gives her audience the opportunity to use her unique promo code when making a Glossier purchase.`,
  },
  {
    logoLight: {
      url: light5,
      alt: 'Alt Text',
    },
    logoDark: {
      url: dark5,
      alt: 'Alt Text',
    },
    type: 'Corporate Social Responsibility Partnership',
    body: `CSR and charity partnerships can help brands drive conversions and give back to causes they care about -- all at the same time. Apollo Neuro, a new stress release wearable, recently partnered with ID.me, the best in class identity verification and authentication platform. Through their partnership, Apollo Neuro is able to provide discounts to specific audiences such as military, veterans, nurses, front-line workers, etc. to help them combat stress, anxiety, PTSD and other conditions.`,
  },
]

export default stories
