import * as React from 'react'
import RichText from 'src/components/rich-text'
import 'src/styles/typing-text.sass'
import { win } from 'src/utils'

class TypingText extends React.Component {
  static defaultProps = {
    characterDelayIn: 40,
    characterDelayOut: 20,
    startInDelay: 0,
    startOutDelay: 0,
    className: '',
    hideCursorDelay: 1800,
    shouldHideCursor: true,
  }

  state = {
    animateIn: false,
    showCursor: false,
    blinkCursor: false,
    maxIndex: 0,
  }

  componentDidMount = () => {
    this.props.animateIn && this.startIn()
  }

  componentDidUpdate = (prevProps) => {
    !prevProps.animateIn && this.props.animateIn && this.startIn()
    prevProps.animateIn && !this.props.animateIn && this.startOut()
  }

  startIn = () => {
    setTimeout(() => {
      this.setState({ showCursor: true, blinkCursor: false })
      const interval = setInterval(() => {
        let maxIndex = this.state.maxIndex + 1
        let totalChars = this.chars.length
        if (maxIndex > totalChars) {
          maxIndex = totalChars
          clearInterval(interval)
          this.setState({ blinkCursor: true })
          setTimeout(() => {
            this.props.shouldHideCursor &&
              this.setState({ showCursor: false, blinkCursor: false })
          }, this.props.hideCursorDelay)
        }
        this.setState({ maxIndex: maxIndex })
      }, this.props.characterDelayIn)
    }, this.props.startInDelay)
  }

  startOut = () => {
    setTimeout(() => {
      const interval = setInterval(() => {
        let maxIndex = this.state.maxIndex - 1
        if (maxIndex < 0) {
          maxIndex = 0
          clearInterval(interval)
        }
        this.setState({ maxIndex: maxIndex })
      }, this.props.characterDelayOut)
    }, this.props.startOutDelay)
  }

  get chars() {
    const parentNode = document.createElement('div')
    parentNode.innerHTML = this.props.copy
    const iter = document.createNodeIterator(parentNode, NodeFilter.SHOW_TEXT)
    let textNode = null
    let chars = []
    while ((textNode = iter.nextNode())) {
      textNode.textContent.split('').forEach((char) => {
        chars = [
          ...chars,
          {
            text: char,
            accent: textNode.parentNode.tagName === 'B',
          },
        ]
      })
    }
    return chars
  }

  render() {
    if (!win) {
      const className = `
        typing-text
        ${this.props.className}
      `
      return <RichText className={className}>{this.props.copy}</RichText>
    }

    const className = `
      typing-text
      ${this.props.className}
      ${this.state.showCursor ? 'show-cursor' : ''}
      ${this.state.blinkCursor ? 'blink-cursor' : ''}
    `
    return (
      <div className={className} style={this.props.style}>
        <div className="animated-text">
          {this.chars.map((char, i) => (
            <span key={i} className={char.accent ? 'accent' : ''}>
              {i + 1 <= this.state.maxIndex ? char.text : ''}
            </span>
          ))}
          <span className="cursor" />
        </div>
        <div className="placeholder-text">
          {this.chars.map((char, i) => (
            <span key={i} className={char.accent ? 'accent' : ''}>
              {char.text}
            </span>
          ))}
        </div>
      </div>
    )
  }
}

export default TypingText
