import React from 'react'
import { connect } from 'react-redux'
import { gotoNextSection } from 'src/state/app'

export const NextSectionButton = (props) => (
  <div
    className="next-section-button"
    onClick={() => props.dispatch(gotoNextSection())}
  >
    {props.nextSectionButtonText}
  </div>
)

export default connect((state) => ({
  nextSectionButtonText: state.app.nextSectionButtonText,
}))(NextSectionButton)
