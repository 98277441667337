import React from 'react'
import 'src/styles/diamond-animation.sass'

export class DiamondAnimation extends React.Component {
  static defaultProps = {
    focused: false,
  }

  render() {
    return (
      <div
        className={`
        diamond-animation
        ${this.props.focused ? 'focused' : ''}
      `}
      >
        <div className="border top" />
        <div className="border right" />
        <div className="border bottom" />
        <div className="border left" />
      </div>
    )
  }
}

export default DiamondAnimation
