import displayAdsThumb from 'src/assets/display-ads-thumb.jpg'
import displayAdsHero from 'src/assets/display-ads-hero.jpg'
import millennialsThumb from 'src/assets/millennials-thumb.jpg'
import millennialsHero from 'src/assets/millennials-hero.jpg'
import consumersThumb from 'src/assets/consumers-thumb.jpg'
import consumersHero from 'src/assets/consumers-hero.jpg'
import bigTechThumb from 'src/assets/big-tech-thumb.jpg'
import bigTechHero from 'src/assets/big-tech-hero.jpg'
import adCostsThumb from 'src/assets/ad-costs-thumb.jpg'
import adCostsHero from 'src/assets/ad-costs-hero.jpg'
import SectionSuspect from 'src/components/section-suspect'

export const displayAds = {
  id: 'display-ads',
  navText: 'Suspect 1',
  Component: SectionSuspect,
  showNav: true,
  showChevron: true,
  name: 'Display ads',
  thumb: { url: displayAdsThumb, alt: 'Alt Text' },
  hero: { url: displayAdsHero, alt: 'Alt Text' },
  guilty: false,
  navText: 'Suspect 1',
  title:
    '<b>Was it display ads</b> with their slow load times on internet browsers?',
  subtitle: '26%* of internet users use ad blockers now to block display ads.',
  source: `<a href="https://impact.com?source=display-ads">*Source: Statista Research Department, "Ad blocking penetration in the U.S. 2014-2021," Statista, January 14, 2021.</a>`,
}

export const millennials = {
  id: 'millennials',
  navText: 'Suspect 2',
  Component: SectionSuspect,
  showNav: true,
  showChevron: true,
  name: 'Millennials',
  thumb: { url: millennialsThumb, alt: 'Alt Text' },
  hero: { url: millennialsHero, alt: 'Alt Text' },
  guilty: false,
  navText: 'Suspect 2',
  title:
    '<b>Was it millennials</b> with their short attention spans in the digital world?',
  subtitle: 'The human attention span has dropped to a meager 8 seconds*.',
  source: `<a href="https://impact.com?source=millennials">*Source: Ranieri & Co. “Changing Attention Span and What It Means for Content in 2021.” Ranieri & Co., Ranieri & Co., 24 May 2021.</a>`,
}

export const consumers = {
  id: 'consumers',
  navText: 'Suspect 3',
  Component: SectionSuspect,
  showNav: true,
  showChevron: true,
  name: 'Consumers',
  thumb: { url: consumersThumb, alt: 'Alt Text' },
  hero: { url: consumersHero, alt: 'Alt Text' },
  guilty: true,
  navText: 'Suspect 3',
  title: '<b>Was it consumers</b> with their lack of trust in advertising?',
  subtitle: '69%* of consumers no longer trust advertising.',
  source: `<a href="https://impact.com?source=consumers">*Source: "When trust falls down." Ipsos MORI, June 29, 2017.</a>`,
}

export const bigTech = {
  id: 'big-tech',
  navText: 'Suspect 4',
  Component: SectionSuspect,
  showNav: true,
  showChevron: true,
  name: 'Big tech',
  thumb: { url: bigTechThumb, alt: 'Alt Text' },
  hero: { url: bigTechHero, alt: 'Alt Text' },
  guilty: false,
  navText: 'Suspect 4',
  title:
    '<b>Was it big tech</b> with their privacy updates and cookie-removal in the near future?',
  subtitle:
    'Google is phasing out third party cookies at the end of this year and 48%* of marketers doubt they can provide key performance metrics without cookies.',
  source: `<a href="https://impact.com?source=big-tech">*Source: Jane Ostler, "Media in 2021: Tough cookies," Kantar, December 9, 2020.</a>`,
}

export const adCosts = {
  id: 'ad-costs',
  navText: 'Suspect 5',
  Component: SectionSuspect,
  showNav: true,
  showChevron: true,
  name: 'Ad costs',
  thumb: { url: adCostsThumb, alt: 'Alt Text' },
  hero: { url: adCostsHero, alt: 'Alt Text' },
  guilty: false,
  navText: 'Suspect 5',
  title:
    '<b>Was it ad costs</b> with their skyrocketing prices within walled gardens?',
  subtitle: '60%* of marketers say customer acquisition costs have increased.',
  source: `<a href="https://impact.com?source=ad-costs">*Source: Decker, Allie. “The Ultimate Guide to Customer Acquisition for 2021.” HubSpot Blog, 17 Feb. 2021</a>`,
}

export const suspects = [displayAds, millennials, consumers, bigTech, adCosts]

export default suspects
