import React from 'react'
import { randomNumber } from 'src/utils'
import 'src/styles/nodes-animation.sass'

export class NodesAnimation extends React.Component {
  static defaultProps = {
    focused: false,
    isBackground: true,
  }

  state = {
    // rotation: Math.floor(randomNumber(-30, 30))
    rotation: 0,
  }

  render() {
    return (
      <div
        className={`
        nodes-animation
        ${this.props.isBackground ? 'background' : 'foreground'}
        ${this.props.focused ? 'focused' : ''}
      `}
        style={{
          transform: `rotate(${this.state.rotation}deg)`,
        }}
      >
        {this.props.isBackground ? (
          <>
            <div className="dots">
              <div className="dot" />
              <div className="dot" />
              <div className="dot" />
            </div>
            <div className="lines">
              <div className="line">
                <div className="stroke" />
              </div>
              <div className="line">
                <div className="stroke" />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="dots">
              <div className="dot" />
              <div className="dot" />
              <div className="dot" />
              <div className="dot" />
              <div className="dot" />
              <div className="dot" />
              <div className="dot" />
              <div className="dot" />
              <div className="dot" />
              <div className="dot" />
            </div>
            <div className="lines">
              <div className="line">
                <div className="stroke" />
              </div>
              <div className="line">
                <div className="stroke" />
              </div>
              <div className="line">
                <div className="stroke" />
              </div>
              <div className="line">
                <div className="stroke" />
              </div>
              <div className="line">
                <div className="stroke" />
              </div>
              <div className="line">
                <div className="stroke" />
              </div>
              <div className="line">
                <div className="stroke" />
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}

export default NodesAnimation
