import SectionForm from 'src/components/section-form'
import logo from 'src/assets/form-logo.svg'

export default {
  id: 'form',
  navText: 'Resources',
  Component: SectionForm,
  showNav: true,
  showChevron: false,
  data: {
    logo: logo,
    title: `It's too late for advertising but don't bury your customer acquisition goals with it. Reach consumers in an authentic way with Impact's Partnership Management Platform.`,
    subtitle: `Speak to a member of our team today to learn more. <br/> Long live partnerships!`,
  },
}
