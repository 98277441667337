import SectionPartnerships from 'src/components/section-partnerships'
import hero from 'src/assets/partnerships-hero.jpg'

export default {
  id: 'partnerships',
  navText: 'Partnerships',
  Component: SectionPartnerships,
  showNav: true,
  showChevron: true,
  data: {
    hero: {
      url: hero,
      alt: 'Alt Text',
    },
    title: `The way forward? <b>Partnerships.</b>`,
    body: `
      <p>Partners can be bloggers, influencers, ambassadors, publications, coupon/loyalty sites, commerce content sites, other brands and more...</p>
      <p><b>And partners are the voices consumers trust when deciding what to purchase</b>. Partnerships make businesses present, relevant and authentic in the lives of consumers. That's why businesses who leverage the partnerships channel are seeing transformative growth, despite ad industry changes.</p>
      <p>Let's take a look at some of the best in class partnerships today.</p>
    `,
    source: `<p><a href='http://impact.com?partnerships-source-link'>*This is the source link.</a></p>`,
  },
}
