import { dispatch } from './redux-wrapper'
import initialState from './state.js'
import { trackSectionView } from 'src/utils'

const TOGGLE_DARKMODE = 'TOGGLE_DARKMODE'
const GOTO_NEXT_SECTION = 'GOTO_NEXT_SECTION'
const GOTO_PREVIOUS_SECTION = 'GOTO_PREVIOUS_SECTION'
const GOTO_SECTION = 'GOTO_SECTION'
const STOP_PLACING = 'STOP_PLACING'
const STOP_ANIMATING = 'STOP_ANIMATING'
const SELECT_ANSWER = 'SELECT_ANSWER'
const DESELECT_ANSWER = 'DESELECT_ANSWER'
const SET_CURRENT_STORY = 'SET_CURRENT_STORY'
const SHOW_STORIES_MODAL = 'SHOW_STORIES_MODAL'
const HIDE_STORIES_MODAL = 'HIDE_STORIES_MODAL'
const SHOW_MOBILE_MENU = 'SHOW_MOBILE_MENU'
const HIDE_MOBILE_MENU = 'HIDE_MOBILE_MENU'

export const hideMobileMenu = () => ({
  type: HIDE_MOBILE_MENU,
})

export const showMobileMenu = () => ({
  type: SHOW_MOBILE_MENU,
})

export const setCurrentStory = (story) => ({
  type: SET_CURRENT_STORY,
  story,
})

export const showStoriesModal = () => ({
  type: SHOW_STORIES_MODAL,
})

export const hideStoriesModal = () => ({
  type: HIDE_STORIES_MODAL,
})

export const gotoSection = (sectionId) => ({
  type: GOTO_SECTION,
  sectionId,
})

export const gotoNextSection = () => ({
  type: GOTO_NEXT_SECTION,
})

export const gotoPreviousSection = () => ({
  type: GOTO_PREVIOUS_SECTION,
})

export const stopPlacing = () => ({
  type: STOP_PLACING,
})

export const stopAnimating = () => ({
  type: STOP_ANIMATING,
})

export const selectAnswer = (suspect) => ({
  type: SELECT_ANSWER,
  suspect,
})

export const deselectAnswer = () => ({
  type: DESELECT_ANSWER,
})

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MOBILE_MENU: {
      return { ...state, shouldShowMobileMenu: true }
    }
    case HIDE_MOBILE_MENU: {
      return { ...state, shouldShowMobileMenu: false }
    }
    case SELECT_ANSWER: {
      return { ...state, selectedAnswer: action.suspect }
    }
    case DESELECT_ANSWER: {
      return { ...state, selectedAnswer: null }
    }
    case SHOW_STORIES_MODAL: {
      return { ...state, storiesModalExpanded: true }
    }
    case HIDE_STORIES_MODAL: {
      return { ...state, storiesModalExpanded: false }
    }
    case STOP_PLACING: {
      return { ...state, placing: false }
    }
    case STOP_ANIMATING: {
      return { ...state, animating: false }
    }
    case SET_CURRENT_STORY: {
      return { ...state, currStory: action.story }
    }
    case GOTO_SECTION: {
      if (state.storiesModalExpanded) return state
      let currSection = state.sections.find((s) => s.id === action.sectionId)
      let prevSection = state.currSection
      if (!currSection || state.animating) return state
      setTimeout(() => dispatch(stopPlacing()), state.placingDelay)
      setTimeout(() => dispatch(stopAnimating()), state.animationDelay)
      trackSectionView(currSection)
      return {
        ...state,
        animating: true,
        placing: true,
        currSection: currSection,
        prevSection: prevSection,
      }
    }
    case GOTO_PREVIOUS_SECTION: {
      if (state.storiesModalExpanded) return state
      let currIndex = state.currSection.index - 1
      let prevIndex = state.currSection.index
      let currSection = state.sections.find((s) => s.index === currIndex)
      let prevSection = state.currSection
      if (!currSection || state.animating) return state
      setTimeout(() => dispatch(stopPlacing()), state.placingDelay)
      setTimeout(() => dispatch(stopAnimating()), state.animationDelay)
      trackSectionView(currSection)
      return {
        ...state,
        animating: true,
        placing: true,
        currSection: currSection,
        prevSection: prevSection,
      }
    }
    case GOTO_NEXT_SECTION: {
      if (state.storiesModalExpanded) return state
      let currIndex = state.currSection.index + 1
      let prevIndex = state.currSection.index
      let currSection = state.sections.find((s) => s.index === currIndex)
      let prevSection = state.currSection
      if (!currSection || state.animating) return state
      setTimeout(() => dispatch(stopPlacing()), state.placingDelay)
      setTimeout(() => dispatch(stopAnimating()), state.animationDelay)
      trackSectionView(currSection)
      return {
        ...state,
        animating: true,
        placing: true,
        currSection: currSection,
        prevSection: prevSection,
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
