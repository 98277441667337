import SectionAnswer from 'src/components/section-answer'

export default {
  id: 'answer',
  navText: 'The Culprit',
  Component: SectionAnswer,
  showNav: true,
  showChevron: true,
  data: {
    correctAnswerText: `
      <p>Nice job, you detective you.</p>
      <p>The <b>consumer</b>. In today's digital world, consumers are in full control. They determine how and when to engage with brands, and they stopped engaging with ads.</p>
      <p>Don’t worry.</p>
      <p>With every crisis comes opportunity.</p>
    `,
    incorrectAnswerText: `
      <p>Wrong, but not completely wrong. <b>{suspectName}</b> certainly did play a role, but this isn't the answer we're looking for.</p>
      <p>The <b>consumer</b>. In today's digital world, consumers are in full control. They determine how and when to engage with brands, and they stopped engaging with ads.</p>
      <p>Don’t worry.</p>
      <p>With every crisis comes opportunity.</p>
    `,
  },
}
