import React from 'react'
import { connect } from 'react-redux'
import { gotoSection } from 'src/state/app'

export const LastSectionButton = (props) => (
  <div
    className="last-section-button"
    onClick={() => props.dispatch(gotoSection('form'))}
  >
    {props.lastSectionButtonText}
  </div>
)

export default connect((state) => ({
  lastSectionButtonText: state.app.lastSectionButtonText,
}))(LastSectionButton)
