import React from 'react'
import { Provider } from 'react-redux'
import { createStore as reduxCreateStore } from 'redux'
import rootReducer from '.'
import { composeWithDevTools } from 'redux-devtools-extension'
import { win } from 'src/utils'

const createStore = () => reduxCreateStore(rootReducer, composeWithDevTools())

export const store = createStore()

export const dispatch = store.dispatch

if (win) win.store = store

const wrapper = ({ element }) => <Provider store={store}>{element}</Provider>

export default wrapper
