import * as React from 'react'
import Section from 'src/components/section'
import TypingText from 'src/components/typing-text'
import RichText from 'src/components/rich-text'
import 'src/styles/section-intro.sass'

class SectionIntro extends React.Component {
  render() {
    return (
      <Section
        style={this.props.style}
        section={this.props.section}
        focused={this.props.focused}
      >
        <div className="content">
          <TypingText
            className="section-title"
            copy={this.props.section.data.title}
            animateIn={this.props.focused}
            startInDelay={350}
            characterDelayIn={60}
            shouldHideCursor={false}
          />
          <RichText className="section-subtitle">
            {this.props.section.data.subtitle}
          </RichText>
        </div>
      </Section>
    )
  }
}

export default SectionIntro
