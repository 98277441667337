import * as React from 'react'
import { connect } from 'react-redux'
import { ReactSVG } from 'react-svg'
import Section from 'src/components/section'
import TypingText from 'src/components/typing-text'
import RichText from 'src/components/rich-text'
import NodesAnimation from 'src/components/nodes-animation'
import DiamondAnimation from 'src/components/diamond-animation'
import NextSectionButton from 'src/components/next-section-button'
import LastSectionButton from 'src/components/last-section-button'
import suspectSticky from 'src/assets/suspect-sticky.svg'
import { randomNumber } from 'src/utils'
import 'src/styles/section-suspect.sass'

class SectionSuspect extends React.Component {
  get suspect() {
    return this.props.suspects.find((s) => s.id === this.props.section.id)
  }

  render() {
    const { suspect } = this
    return (
      <Section
        className={`
        section-suspect
        ${this.props.focused ? 'focused' : ''}
       `}
        style={this.props.style}
        section={this.props.section}
        focused={this.props.focused}
      >
        <div className="content">
          <div className="copy">
            <TypingText
              className="question"
              copy={this.props.suspectQuestion}
              animateIn={this.props.focused}
              startInDelay={200}
              characterDelayIn={20}
            />
            <TypingText
              className="section-title"
              copy={suspect.title}
              animateIn={this.props.focused}
              startInDelay={1000}
              characterDelayIn={25}
            />
            <p className="section-subtitle">{suspect.subtitle}</p>
            <div className="buttons">
              <NextSectionButton />
              <LastSectionButton />
            </div>
            <div className="source">
              <RichText>{suspect.source}</RichText>
            </div>
          </div>
          <div className="hero">
            <div className="diamond">
              <DiamondAnimation focused={this.props.focused} />
            </div>
            <div className="nodes-background">
              <NodesAnimation
                isBackground={true}
                focused={this.props.focused}
              />
            </div>
            <div className="suspect-image">
              <img src={suspect.hero.url} alt={suspect.hero.alt} />
            </div>
            <div className="nodes-foreground">
              <NodesAnimation
                isBackground={false}
                focused={this.props.focused}
              />
            </div>
            <div className="suspect-sticky">
              <ReactSVG src={suspectSticky} />
            </div>
          </div>
        </div>
      </Section>
    )
  }
}

export default connect((state) => ({
  suspects: state.app.suspects,
  suspectQuestion: state.app.suspectQuestion,
}))(SectionSuspect)
