import SectionIntro from 'src/components/section-intro'

export default {
  id: 'intro',
  navText: 'Start',
  Component: SectionIntro,
  showNav: false,
  showChevron: true,
  data: {
    title: 'Advertising is <b>dead</b>.',
    subtitle: "Scroll to find out who's responsible.",
  },
}
