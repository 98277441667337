import * as React from 'react'
import Section from 'src/components/section'
import 'src/styles/section-landing.sass'
import wheelBorder from 'src/assets/landing-wheel-outline.svg'
import { connect } from 'react-redux'
import { gotoNextSection } from 'src/state/app'

class LandingSection extends React.Component {
  render() {
    return (
      <Section
        style={this.props.style}
        section={this.props.section}
        focused={this.props.focused}
      >
        <div className="content">
          <div className="wheel">
            <img className="border" src={wheelBorder} alt="Wheel Border" />
            <div className="center">
              <div className="desktop-long-copy">
                {this.props.section.data.longCopy}
              </div>
              <div className="mobile-short-copy">
                {this.props.section.data.shortCopy}
              </div>
            </div>
            <div className="suspect-thumbs">
              {this.props.suspects.map((suspect) => (
                <div
                  onClick={() => this.props.dispatch(gotoNextSection())}
                  key={suspect.id}
                  className={`thumb thumb-${suspect.id}`}
                >
                  <img src={suspect.thumb.url} alt={suspect.thumb.alt} />
                </div>
              ))}
            </div>
          </div>
          <div className="mobile-long-copy">
            {this.props.section.data.longCopy}
          </div>
        </div>
      </Section>
    )
  }
}

export default connect((state) => ({
  suspects: state.app.suspects,
}))(LandingSection)
