import * as React from 'react'
import { connect } from 'react-redux'
import Section from 'src/components/section'
import RichText from 'src/components/rich-text'
import correctAnswerIcon from 'src/assets/correct-answer-icon.svg'
import incorrectAnswerIcon from 'src/assets/incorrect-answer-icon.svg'
import { ReactSVG } from 'react-svg'
import { gotoSection } from 'src/state/app'
import 'src/styles/section-answer.sass'

class SectionAnswer extends React.Component {
  render() {
    return (
      <Section
        style={this.props.style}
        section={this.props.section}
        focused={this.props.focused}
      >
        <div className="content">
          <div className="hero">
            <img src={this.props.hero.url} alt={this.props.hero.alt} />
          </div>
          <div className="copy">
            <div className="icon">
              <ReactSVG
                src={
                  this.props.correct ? correctAnswerIcon : incorrectAnswerIcon
                }
              />
            </div>
            <RichText>
              {this.props.correct
                ? this.props.section.data.correctAnswerText
                : this.props.section.data.incorrectAnswerText.replace(
                    '{suspectName}',
                    this.props.selectedAnswerName
                  )}
            </RichText>
          </div>
        </div>
      </Section>
    )
  }
}

export default connect((state) => ({
  hero: state.app.correctAnswer.hero,
  selectedAnswerName: state.app.selectedAnswer
    ? state.app.selectedAnswer.name
    : '',
  correct:
    !state.app.selectedAnswer ||
    (state.app.selectedAnswer &&
      state.app.selectedAnswer.id === state.app.correctAnswer.id),
}))(SectionAnswer)
