import intro from './intro'
import landing from './landing'
import suspects from '../suspects'
import guessWho from './guess-who'
import answer from './answer'
import partnerships from './partnerships'
import stories from './stories'
import form from './form'

export const sections = [
  intro,
  landing,
  ...suspects,
  guessWho,
  answer,
  partnerships,
  stories,
  form,
].map((section, i) => ({
  index: i + 1,
  ...section,
}))

export default sections
