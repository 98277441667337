import merge from 'deepmerge'
import suspects from './suspects'
import sections from './sections'
import stories from './stories'

const defaultState = {
  currSection: null,
  prevSection: null,
  animating: false,
  placing: false,
  animationDelay: 1500,
  placingDelay: 20,
  correctAnswer: null,
  selectedAnswer: null,
  storiesModalExpanded: false,
  shouldShowMobileMenu: false,
  closeModalTextcloseModalText: 'Close',
  nextSectionButtonText: 'Next suspect',
  lastSectionButtonText: 'SKIP TO END →',
  suspectQuestion: 'Who killed advertising?',
  currStory: null,
  suspects: suspects,
  sections: sections,
  stories: stories,
}

const mergeState = () => {
  try {
    const data = require('./state.json')
    const state = merge.all([defaultState])
    const mergeSection = (id) => {
      const section = state.sections.find((s) => s.id === id)
      const index = state.sections.indexOf(section)
      state.sections[index] = merge.all([section, data.sections[id] || {}])
    }
    state.nextSectionButtonText =
      data.nextSectionButtonText || state.nextSectionButtonText
    state.lastSectionButtonText =
      data.lastSectionButtonText || state.lastSectionButtonText
    state.closeModalText = data.closeModalText || state.closeModalText
    mergeSection('intro')
    mergeSection('landing')
    mergeSection('guess-who')
    mergeSection('answer')
    mergeSection('partnerships')
    mergeSection('stories')
    mergeSection('form')
    state.stories = data.stories || state.stories
    state.suspects.forEach((suspect, i) => {
      const suspectData = data.suspects[suspect.id]
      state.suspects[i] = merge.all([suspect, suspectData])
    })
    state.currSection = state.sections[0]
    state.currStory = state.stories[0]
    state.correctAnswer = state.suspects.find((s) => s.guilty)
    return state
  } catch (err) {
    console.log('Error Merging State:', err)
    defaultState.currSection = defaultState.sections[0]
    defaultState.currStory = defaultState.stories[0]
    defaultState.correctAnswer = defaultState.suspects.find((s) => s.guilty)
    return defaultState
  }
}

export default mergeState()
