import * as React from 'react'
import Section from 'src/components/section'
import RichText from 'src/components/rich-text'
import 'src/styles/section-partnerships.sass'

class SectionPartnerships extends React.Component {
  render() {
    return (
      <Section
        style={this.props.style}
        section={this.props.section}
        focused={this.props.focused}
      >
        <div className="content">
          <div className="copy">
            <RichText className="section-title-inter">
              {this.props.section.data.title}
            </RichText>
            <RichText className="body">{this.props.section.data.body}</RichText>
            <RichText className="source">
              {this.props.section.data.source}
            </RichText>
          </div>
          <div className="hero">
            <img
              src={this.props.section.data.hero.url}
              alt={this.props.section.data.hero.alt}
            />
          </div>
        </div>
      </Section>
    )
  }
}

export default SectionPartnerships
