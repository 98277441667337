import * as React from 'react'
import { connect } from 'react-redux'
import Section from 'src/components/section'
import { setCurrentStory, showStoriesModal } from 'src/state/app'
import 'src/styles/section-stories.sass'

class SectionStories extends React.Component {
  render() {
    return (
      <Section
        style={this.props.style}
        section={this.props.section}
        focused={this.props.focused}
      >
        <div className="content">
          <div className="logo">
            <img src={this.props.section.data.logo} alt="Logo" />
          </div>
          <div className="section-title-inter">
            {this.props.section.data.title}
          </div>
          <div className="section-subtitle">
            {this.props.section.data.subtitle}
          </div>
          <div className="stories">
            {this.props.stories.map((story, i) => (
              <div
                key={i}
                className="story"
                style={{
                  transitionDelay: `${0.1 * (i + 1)}s`,
                }}
                onClick={() => {
                  this.props.dispatch(setCurrentStory(story))
                  this.props.dispatch(showStoriesModal())
                }}
              >
                <div className="thumb">
                  <img src={story.logoLight.url} alt={story.logoLight.alt} />
                </div>
                <div className="type">{story.type}</div>
              </div>
            ))}
          </div>
        </div>
      </Section>
    )
  }
}

export default connect((state) => ({
  stories: state.app.stories,
}))(SectionStories)
