import SectionGuessWho from 'src/components/section-guess-who'
export default {
  id: 'guess-who',
  navText: 'Who Did It?',
  Component: SectionGuessWho,
  showNav: true,
  showChevron: true,
  data: {
    title: 'So who do you think did it?',
    subtitle: 'Select your answer.',
  },
}
