import SectionStories from 'src/components/section-stories'
import logo from 'src/assets/stories-logo.svg'
export default {
  id: 'stories',
  navText: 'Stories',
  Component: SectionStories,
  showNav: true,
  showChevron: true,
  data: {
    logo: logo,
    title: 'Partnerships in action',
    subtitle:
      'Click on the logos below to learn more about the partnership story.',
  },
}
